.frame-content {
    .glass {
        height: 20vh;
    }
    .logo {
        height: 4rem;
    }
    .photo {
        width: 60%;
    }
}

