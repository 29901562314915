@import "base";
@import "admin";

$cc-red: rgb(171, 50, 59); //#96363F
$cc-green: rgb(161, 184, 168); //#A1B8A8
$cc-black: black;
$cc-white: white;
$cc-link: #2a7ae2 !default;
$cc-yellow: #efdec4;

$index-menu-height-desktop: 5rem;
$index-menu-height-phone: 3rem;
$index-menu-height: 4rem;

* {
    letter-spacing: 0.2em;
}

body {
    background-image: url("/assets/images/bg-pattern.jpg");
    background-repeat: repeat;
    font-size: $base-font-size;

    @include media-query($on-phone) {
        font-size: $base-font-size * 0.75;
    }
}

h1, h2, h3, h4, h5 {
	font-family: NaiveInlineShadowBold;
}

h2 {
    color: $cc-red;
    letter-spacing: 0.1rem;
}

h3 {
    color: $cc-black;
    letter-spacing: 0.1rem;
}

p {
    margin-bottom: 18px;
}

blockquote {
    font-size: $base-font-size;
    color: $cc-black;
    border-left: 4px solid $cc-green;

    margin-top: 1rem;
    margin-bottom: 2rem;

    @include media-query($on-tablet) {
        font-size: $base-font-size * 0.72;
    }
}

article {
    margin-top: 2rem;
    text-align: left;
    hyphens: auto;
    -webkit-hyphens: auto;
    -webkit-hyphenate-locale: "sv_SE";

    h2,
    h3,
    h4 {
        margin-top: 1.5rem;
    }
    ul {
        align-items: flex-start;
    }
    * {
        text-align: left;
        letter-spacing: 0.1rem;
    }
    time {
        color: $cc-red;
    }
    section {
        margin-top: 0;
        padding-top: 4rem;
    }
    @include media-query($on-tablet) {
        section {
            padding-top: 2rem;
        }
        h2,
        h3,
        h4 {
            margin-top: 1rem;
        }
    }
}

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: #{$grey-color};
    padding-right: 5px;
    vertical-align: text-top;
}

.social-media-list {
    a {
        border-bottom: none;
    }

    li + li {
        padding-top: 5px;
    }
}

.meny,
.booking {
    h3 {
        padding-top: 1.5rem;
        padding-bottom: 0rem;
    }
    ul {
        &.contact {
            li {
                display: list-item;
            }
        }
        &:not(.contact) {
            li {
                display: grid;
                //padding-bottom: 0.5rem;
                &:not(:first-child)::before {
                    content: "~ • ~";
                    letter-spacing: 0.1em;
                    color: $cc-red;
                }

                a {
                    border: none;
                    text-decoration: underline;
                }
            }
        }
    }
}

.center {
    text-align: center;
}

.visa-inte {
    display: none;
}

a {
    text-decoration: none;
    color: lighten($cc-black, 0%);
    border-bottom: 1px $cc-black solid;
    &:hover::not(button) {
        color: $cc-red;
        text-decoration: none;
    }
    &:visited {
        color: lighten($cc-black, 0%);
    }
    &.image {
        border: none;
    }
}

ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;

    margin-bottom: 1rem;
    li {
        margin-bottom: 0.3rem;
    }

    &.social {
        li {
            padding-bottom: 0.5rem;
        }
        margin-bottom: 0rem;
    }

    &.divided {
        flex-direction: row;
        justify-content: center;

        li:not(:first-child):not(.no-divider)::before {
            content: "⋮";
            color: $cc-red;
            letter-spacing: 0.5em;
        }
    }

    &.social-media-list {
        img {
            width: 18px;
            height: 18px;
        }
    }
}

.site-header {
    padding-top: 0rem;
    padding-bottom: 0.6rem;
    .menu {
        font-family: $header-font-family;
        padding-bottom: 0.6rem;
        .menu-row {
            &:first-of-type {
                margin-bottom: 0rem;
                padding-top: 0.8rem;
            }
            margin-bottom: 0rem;
            font-size: $base-font-size;
            li {
                white-space: nowrap;
                margin-bottom: 0;
                a {
                    &:not(.button, button) {
                        color: lighten($cc-black, 0%);
                        //color: $cc-red;
                        border-bottom: none;
                        &:hover {
                            color: $cc-red;
                        }
                    }
                }
            }

            @include media-query($on-tablet) {
                font-size: $base-font-size * 0.9;
            }
            @include media-query($on-phone) {
                font-size: $base-font-size * 0.8;
            }
            @include media-query($on-small-phone) {
                font-size: $base-font-size * 0.6;
            }
        }
    }
}

.index-menu {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    font-family: $header-font-family;
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    padding-bottom: 1rem;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    align-content: center;
    height: $index-menu-height; //-desktop;
    @include media-query($on-phone) {
        height: $index-menu-height-phone;
    }
    z-index: 3;
    background-image: url("/assets/images/bg-pattern.jpg");
    background-repeat: repeat;
    padding-left: 1rem;
    padding-right: 1rem;

    &.stuck {
        -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.36);
        -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.36);
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.36);
        .menu {
            .menu-row {
                &.w-button {
                    .button {
                        //height: 3rem;
                        background-color: rgba($cc-green, 0.73);
                        padding-left: 24px;
                        padding-right: 24px;
                        //@include media-query($on-phone) {
                        //    height: 2.8rem;
                        //}
                    }
                }
            }
        }
        //@include media-query($on-tablet) {
        //    padding-top: 1rem;
        //    height: $index-menu-height;
        //}
    }


    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;

        img {
            width: 120px;
         }

         @include media-query($on-phone) {
            img {
                width: 90px;
             }
        }
        @include media-query($on-small-phone) {
            img {
                width: 80px;
             }
        }


        .menu-row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            margin-bottom: 0;
            list-style-type: none;
            width: 100%;

            &.w-button {
                width: 110%;
                .button {
                    font-family: $header-font-family;
                    border-color: $cc-black;
                    height: 3rem;
                    letter-spacing: 0.7px;
                    font-weight: 600;
                    transition-duration: 0.75s;
                    @include media-query($on-phone) {
                        height: 2.4rem;
                    }
                }
            }
            gap: 0.3rem;
            li {
                a {
                    &:not(.button, button) {
                        color: lighten($cc-black, 0%);
                        //color: $cc-red;
                        border-bottom: none;
                        &:hover {
                            color: $cc-red;
                        }
                    }
                }
            }

            @include media-query($on-tablet) {
                margin-bottom: 0;
            }
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

.site-header {
    position: fixed;
    display: flex;
    flex-direction: row;
    z-index: 10;
    justify-content: center;
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
    height: $index-menu-height-desktop;
    background-image: url("/assets/images/bg-pattern.jpg");
    background-repeat: repeat;
    -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.36);
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.36);

    nav {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
        height: auto;
    }

    .menu-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        justify-content: space-between;
        padding-top: 10px;

        li {
            width: 50%;
        }
        img#header-logo {
            width: 5rem;
        }
    }

    @include media-query($on-tablet) {
        height: $index-menu-height;
    }
}

.page-content {
    background-image: url("/assets/images/bg-pattern.jpg");
    background-repeat: repeat;

    padding-top: 5rem;

    &.home {
        padding-top: 2rem;
    }

    img {
        &.glass {
            height: 20vh;
        }
        &.photo {
            width: 60%;
            &.minis {
                width: 85%;
                margin-bottom: 2rem;
            }
        }
        &.logo {
            height: 4rem;
        }
        &.cave-logo {
            width: 70%;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    .photo-grid {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        img {
            &.photo-thumb {
                width: 31%;
                @include media-query($on-tablet) {
                    width: 48%;
                }
            }
        }
    }

    .social-media-list {
        margin-top: 1rem;
    }

    ul {
        &.contact {
            margin-bottom: 2rem;
        }
    }

    .hero {
        padding-bottom: 0rem;
        h2,
        h3 {
            color: $cc-black;
            padding-top: 0.3rem;
            padding-bottom: 0rem;
            margin-bottom: 0.5rem;
        }

        img {
            &.glass {
                height: 40vmin;
                @include media-query($on-phone) {
                    height: 80vmin;
                }
                margin-top: 0rem;
                margin-bottom: 1.5rem;
            }
        }
    }

    .wrapper {
        z-index: 1;
    }
}

.social {
    li {
        margin-bottom: 0.3rem;
        a {
            &:hover {
                color: $cc-red;
            }
            &:visited {
                color: $cc-black;
            }
        }
    }
}

.wrapper {
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0rem;

    @include media-query($on-phone) {
        font-size: $base-font-size * 0.75;

        img {
            &.photo,
            &.photo.minis {
                width: 100%;
            }
        }
    }
}

.wrapper {
    &:first-of-type {
        section {
            padding-top: 8rem;
            @include media-query($on-tablet) {
                padding-top: 2rem;
            }
        }
    }
    section {
        padding-top: $index-menu-height-desktop;
        @include media-query($on-tablet) {
            padding-top: $index-menu-height;
        }
        @include media-query($on-phone) {
            padding-top: $index-menu-height-phone;
        }
    }
}

section {
    padding-bottom: 0rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &.hero {
        @include media-query($on-tablet) {
            padding-top: 0.5rem;
        }
    }

    &.social {
        &:first-child {
            padding-top: 1.2rem;
        }
    }
}

.booking {
    section {
        padding-top: 3rem;
        @include media-query($on-tablet) {
            padding-top: 0;
            margin-top: 0;
        }
        .glass {
            margin-bottom: 1.5rem;
        }
    }
}

.post {
    .post-content {
        section {
            align-items: flex-start;
        }
    }
}

form {
    width: 100%;
}

textarea {
    width: 100%;
}

input {
    &[type="text"],
    &[type="email"],
    &[type="password"] {
        width: 100%;
    }
}

.site-footer {
    font-size: $base-font-size;
    background-image: url("/assets/images/bg-pattern.jpg");
    background-repeat: repeat;
    border-top: 2.5rem solid $cc-red;
    border-bottom: 2.5rem solid $cc-red;
    text-align: left;
    padding: 20px 0;
    margin-top: 7rem;

    ul {
        text-align: left;
        align-items: flex-start;
    }

    .wrapper {
        max-width: 800px;
    }

    .footer-col-wrapper {
        color: $cc-black;
        margin-left: 0;
        display: flex;
        flex-direction: row;
        @extend %clearfix;

        .footer-col {
            margin-bottom: 0;
            padding-left: 0;
            width: auto;
            flex-grow: 1;
            &.footer-col-1 {
                margin-bottom: 1.2rem;
                padding-right: 2rem;
            }
            &.footer-col-2 {
                padding-left: 1rem;
                padding-right: 1rem;
            }
            &.footer-col-3 {
                text-align: left;
            }
        }
    }

    img {
        &.logo {
            vertical-align: top;
            height: 8rem;
            margin-top: -1.6rem;
        }
    }

    @include media-query($on-tablet) {
        text-align: center;
        ul {
            text-align: center;
            align-items: center;
        }
        .footer-col-wrapper {
            font-size: $base-font-size * 0.75;
            flex-direction: column;
            align-items: center;
            .footer-col {
                float: none;
                &.footer-col-1,
                &.footer-col-2,
                &.footer-col-3 {
                    padding-left: 0rem;
                    padding-right: 0rem;
                }
            }
        }

        img {
            &.logo {
                margin-top: 0;
                height: 4vh;
                max-height: 4rem;
                min-height: 3rem;
            }
        }
    }
}

.after-footer {
    background-image: url("/assets/images/bg-pattern.jpg");
    background-repeat: repeat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 600px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
}

.button,
input,
textarea {
    border: 0.07rem solid $cc-black;
    border-radius: 0.3rem;

    font-size: $base-font-size;
    font-family: $header-font-family;

    @include media-query($on-phone) {
        font-size: $base-font-size * 0.75;
    }
}

.button {
    color: $cc-black;
    padding: 1rem 2rem;
    background-color: transparent;
    padding: 12px 14px 8px 14px;
    margin-bottom: 12px;
    line-height: 100%;
    border: 0.07rem solid;

    &:visited {
        color: $cc-black;
    }
    &:hover {
        color: $cc-red;
        text-decoration: none;
        background-color: lighten($cc-green, 10%);
    }
    &:active {
        background-color: darken($cc-green, 10%);
    }
    @include media-query($on-tablet) {
        margin-bottom: 8px;
    }
}

input,
textarea {
    padding: 0.5rem;
    margin-top: 0.5rem;
    box-sizing: border-box;
    background-color: $cc-white;
}

#map {
    height: 50vh;
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 1rem;
}

.home {
    #map {
        height: 35vh;
        margin-bottom: 1rem;
    }

    section {
        &:first-of-type {
            @include media-query($on-tablet) {
                margin-top: 1rem;
            }
        }
        padding-top: 8rem;
        @include media-query($on-tablet) {
            padding-top: 5rem;
        }
    }
}
