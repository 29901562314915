@charset "utf-8";

/* latin */
@font-face {
  font-family: 'Special Elite';
  font-style: normal;
  font-weight: 400;
  src: local('Special Elite Regular'), local('SpecialElite-Regular'), url(/assets/SpecialElite-Regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


// Define defaults for each variable.

$base-font-family: "Special Elite", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;
$header-font-family: NaiveInlineShadowBold, "Special Elite", "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-small-phone:   340px !default;
$on-phone:         600px !default;
$on-tablet:        800px !default;

// Use media queries like this:
// @include media-query($on-phone) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}


 /**
  * Clearfix
  */
  %clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
  
  

// Resets

/**
 * Reset some basic elements
 */
 body, h1, h2, h3, h4, h5, h6,
 p, blockquote, pre, hr,
 dl, dd, ol, ul, figure {
   margin: 0;
   padding: 0;
 }
 
 
 
 /**
  * Basic styling
  */
 body {
   font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
   color: $text-color;
   background-color: $background-color;
   -webkit-text-size-adjust: 100%;
   -webkit-font-feature-settings: "kern" 1;
      -moz-font-feature-settings: "kern" 1;
        -o-font-feature-settings: "kern" 1;
           font-feature-settings: "kern" 1;
   font-kerning: normal;
 }
 
 
 
 /**
  * Set `margin-bottom` to maintain vertical rhythm
  */
 h1, h2, h3, h4, h5, h6,
 p, blockquote, pre,
 ul, ol, dl, figure,
 %vertical-rhythm {
   margin-bottom: $spacing-unit / 2;
 }
 
 
 
 /**
  * Images
  */
 img {
   max-width: 100%;
   vertical-align: middle;
 }
 
 
 
 /**
  * Figures
  */
 figure > img {
   display: block;
 }
 
 figcaption {
   font-size: $small-font-size;
 }
 
 
 
 /**
  * Lists
  */
 ul, ol {
   margin-left: $spacing-unit;
 }
 
 ul {
   margin-left: 0;
   list-style-type: none;
   text-align: center;
 }
 
 li {
   > ul,
   > ol {
     margin-bottom: 0;
   }
 }
 
 
 
 /**
  * Headings
  */
 h1, h2, h3, h4, h5, h6 {
   font-weight: $base-font-weight;
 }
 
 /**
  * Blockquotes
  */
 blockquote {
   color: $grey-color;
   border-left: 4px solid $grey-color-light;
   padding-left: $spacing-unit / 2;
   @include relative-font-size(1.125);
   letter-spacing: -1px;
   font-style: italic;
 
   > :last-child {
     margin-bottom: 0;
   }
 }
 
 
 
 /**
  * Code formatting
  */
 pre,
 code {
   @include relative-font-size(0.9375);
   border: 1px solid $grey-color-light;
   border-radius: 3px;
   background-color: #eef;
 }
 
 code {
   padding: 1px 5px;
 }
 
 pre {
   padding: 8px 12px;
   overflow-x: auto;
 
   > code {
     border: 0;
     padding-right: 0;
     padding-left: 0;
   }
 }
 
 
 
 /**
  * Wrapper
  */
 
 /**
  * Tables
  */
 table {
   margin-bottom: $spacing-unit;
   width: 100%;
   text-align: $table-text-align;
   color: lighten($text-color, 18%);
   border-collapse: collapse;
   border: 1px solid $grey-color-light;
   tr {
     &:nth-child(even) {
       background-color: lighten($grey-color-light, 6%);
     }
   }
   th, td {
     padding: ($spacing-unit / 3) ($spacing-unit / 2);
   }
   th {
     background-color: lighten($grey-color-light, 3%);
     border: 1px solid darken($grey-color-light, 4%);
     border-bottom-color: darken($grey-color-light, 12%);
   }
   td {
     border: 1px solid $grey-color-light;
   }
 }
 